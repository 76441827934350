import { capitalCase } from 'change-case';

// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Alert, Tooltip, Container, Typography, Button } from '@mui/material';
// routes

// hooks
import useAuth from '../../hooks/useAuth';
// layouts

// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(() => ({
  width: '100%',
  maxWidth: 1024,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: 0,
  padding: 0,
  // margin: theme.spacing(2, 0, 2, 2),x
  backgroundImage: `url(/images/landing-min.jpg)`, // Add this line
  backgroundRepeat: 'no-repeat', // And this line
  backgroundSize: 'cover'
}));

const ContentStyle = styled('div')(() => ({
  maxWidth: 768,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center'
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | RIMS Dashboard">
      <MHidden width="mdDown">
        <SectionStyle />
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to RIMS Portal
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>

            <Tooltip title={capitalCase(method)}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip>
          </Stack>

          {method === 'firebase' && <AuthFirebaseSocials />}

          <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@yourtechleads.com</strong> / password :<strong>&nbsp;test123</strong>
          </Alert>

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              Login
            </Button>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
