import PropTypes from 'prop-types';
// material

import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img src="/favicon/android-chrome-512x512.png" alt="Ministry of agriculture logo" />
    </Box>
  );
}
