import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/overview" replace /> },
        { path: 'overview', element: <Overview /> },
        { path: 'reports', element: <Reports /> },
        { path: 'districts', element: <Districts /> },
        {
          path: 'users',
          children: [
            { element: <Navigate to="/users/list" replace /> },
            { path: 'list', element: <Users /> },
            { path: 'new', element: <AddUser /> }
          ]
        },
        { path: '404', element: <NotFound /> },
        { path: '500', element: <Page500 /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: []
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Overview = Loadable(lazy(() => import('../pages/dashboard/overview')));
const Users = Loadable(lazy(() => import('../pages/dashboard/users')));
const AddUser = Loadable(lazy(() => import('../pages/dashboard/users/AddUser')));
const Reports = Loadable(lazy(() => import('../pages/dashboard/reports')));
const Districts = Loadable(lazy(() => import('../pages/dashboard/districts')));
const NotFound = Loadable(lazy(() => import('../pages/dashboard/system/Page404')));
const Page500 = Loadable(lazy(() => import('../pages/dashboard/system/Page500')));
